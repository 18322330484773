import LoadingAnimation from 'components/Loader'
import * as S from './styled'

export function OnboardingScreen() {
  return (
    <S.OnboardingContainer>
      <S.OnboardingScreenOverlayBlock />
      <S.EventWrapper>
        <S.EventTitle>MEXC</S.EventTitle>
      </S.EventWrapper>
      <S.EventWrapper>
        <S.EventTitle>Referral month!</S.EventTitle>
      </S.EventWrapper>
      <LoadingAnimation />
      <S.LoadingText>Loading</S.LoadingText>
      <S.OnboardingTitle>
        Welcome to the <br /> ultimate coin quest!
      </S.OnboardingTitle>
      <S.OnboardingSubtitle>
        Collect coins, level up, and unlock exciting rewards—let the adventure
        begin!
      </S.OnboardingSubtitle>
    </S.OnboardingContainer>
  )
}
