import styled, { keyframes } from 'styled-components'
import { COLORS } from 'utils/constants'

export const FarmView = styled.div`
  position: relative;
  padding-block: 10px 0;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-height: 756px) {
    padding-block: 48px 0;
  }

  @media (max-height: 680px) {
    padding-block: 24px 0;
  }

  @media (max-height: 650px) {
    padding-block: 12px 0;
  }
`

const moveSanta = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, -80%);
  }
  10% {
    opacity: 1;
  }
  80% {
    top: 13%;
    transform: translate(0, 13%);
    opacity: 1;
  }
  81% {
    top: 13%;
    transform: translate(0, 13%);
    opacity: 0;
  }
  100% {
    top: 13%;
    transform: translate(0, 13%);
    opacity: 0;
  }
`

export const GiftWrapper = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 13%;
  right: 0;
  z-index: 998;
`

export const StoreIconWrapper = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 13%;
  left: 10px;
  z-index: 998;
`

export const TicketWrapper = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 28%;
  right: 0;
  z-index: 998;
`

export const SantaWrapper = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 80%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 997;
  animation: ${moveSanta} 8s linear infinite;
`

export const TotalCoinsWrapper = styled.div`
  margin-bottom: 80px;
  @media (max-height: 750px) {
    margin-bottom: 25px;
  }
  @media (max-height: 710px) {
    margin-bottom: 10px;
  }
`

export const FarmHeading = styled.div``

export const FarmTile = styled.h2`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${COLORS.blue};

  @media (max-height: 650px) {
    font-size: 14px;
    line-height: 22px;
  }
`

export const FarmDescription = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${COLORS.white};

  @media (max-height: 650px) {
    font-size: 12px;
    line-height: 18px;
  }
`

export const FormFarmingInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${COLORS.black_28};
  border: 1px solid ${COLORS.gray_3B};
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.gray};
  margin-top: auto;
  margin-bottom: 24px;
`

export const FormStartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 24px;
  left: 10px;
  right: 10px;
`

export const Timer = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
`

export const TotalCoinInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;

  @media (max-height: 600px) {
    margin-bottom: 12px;
  }
`

export const CoinIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

export const CoinValue = styled.span`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: ${COLORS.white_f8};
  display: flex;
  align-items: center;
  gap: 8px;
`

export const CoinLabel = styled.span`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.gray};
`

export const HomeContainer = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledImage = styled.img``
