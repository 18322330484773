export function GetGemsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      viewBox='0 0 1376 1440'
      width='null'
      height='null'
    >
      <path
        transform='translate(650,218)'
        d='m0 0h22l16 3 5 2 4 11 3 16 1 9v26l-3 28-5 26-8 30-9 26-11 26-8 16-12 23-12 19-11 16-10 13-11 13-9 10-5 6-4 4h-2v2l-8 7-7 7-11 9-12 10-19 14-15 10-21 13-14 8-16 8-28 13-33 13-31 11-36 12-21 9-9 7-3 4-1 9 3 6 9 6 16 5 37 8 36 8 31 9 20 7 23 10 16 8 20 11 12 8 14 10 11 9 14 12 23 23 9 11 10 13 12 18 10 17 11 21 12 28 12 35 12 43 10 37 6 16 6 10 8 5 8-1 6-4 7-11 7-19 19-66 12-35 11-27 16-33 10-18 14-22 14-19 9-11 18-20 9-9 8-7 14-12 18-13 17-11 19-11 19-10 31-13 28-10 37-10 29-6 29-4 20-2 31-1 13 1 1 1 1 25v15l-2 26-5 30-9 36-8 25-9 25-12 28-10 21-13 23-12 19-12 17-14 18-12 14-9 10-14 15-3 1-2 4-10 9-11 10-11 9-15 12-18 13-23 15-21 12-17 9-28 12-25 9-23 7-30 7-29 5-29 3h-70l-38-4-43-8-34-9-33-11-24-10-26-13-27-16-19-13-21-16-11-9-10-9-8-7-28-28-9-11-12-14-12-16-16-23-16-27-12-23-10-21-11-28-11-33-9-36-6-38-3-30v-54l4-36 5-29 8-34 8-26 9-26 11-26 12-25 13-24 14-22 12-17 9-12 9-11 12-14 14-15 15-15 8-7 13-11 20-15 21-14 20-12 22-12 16-8 17-8 30-12 36-12 36-9 26-4z'
        fill='#FEFEFE'
      />
      <path
        transform='translate(1084,260)'
        d='m0 0h27l13 3 4 4 4 11 2 12v32l-3 21-5 23-9 29-8 21-9 20-8 16-9 17-12 19-11 16-13 17-12 14-16 17-20 18-14 11-13 10-18 12-18 11-24 13-26 12-30 11-24 7-25 5-17 2h-26l-14-2-12-4-4-3-3-10-2-12v-20l3-23 6-26 11-33 11-26 15-30 13-22 12-18 8-11 10-13 9-11 22-24 20-18 14-11 19-14 17-11 24-14 21-11 32-14 28-10 25-7 26-5z'
        fill='#FEFEFE'
      />
    </svg>
  )
}
