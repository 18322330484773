import VoyageIcon from 'assets/icons/VoyageIcon'
import { Image } from 'assets/images'
import Button from 'common/Button'
import { Dialog } from 'common/Dialog'
import Skeleton from 'common/Skeleton'
import BuyLotteryContent from 'components/BuyLotteryContent'
import DailyRewardsContent from 'components/DailyRewardsContent'
import BubbleAnimation from 'components/FarmBubbleAnimation'
import { LotteryCongratulationsModal } from 'components/LotteryCongratulationsModal'
import { TotalERVCoin } from 'components/TotalERVCoin'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { farmSelector } from 'store/farm/selectors'
import { farmActions } from 'store/farm/slice'
import { shopSelector } from 'store/shop/selectors'
import { shopActions } from 'store/shop/slice'
import { userSelector } from 'store/user/selectors'
import { userActions } from 'store/user/slice'
import { LOADING_STATUSES, ROUTES, TASK_BUTTONS_STATES } from 'utils/constants'
import { getFarmingLabel } from 'utils/farm'
import { formatCoin } from 'utils/format-coin'
import { formatTime } from 'utils/format-time'
// import { shouldOpenDailyModal } from 'utils/modal'
import { SpecialCard } from 'assets/icons/SpecialCard'
import * as S from './styled'
import PrizePopUpContent from '../PrizePopUpContent'
import TonPopUpContent from '../TonPopUpContent'

function FarmForm() {
  const {
    coinsPerSecond,
    timeLeft,
    farmedCoins,
    getFarmingStatus,
    autoFarming,
    coinsPerSession,
    multiplier,
  } = useSelector(farmSelector)
  const {
    boughtLotteries,
    latestInvoiceLink,
    getLatestLotteryTicketsStatus,
    getInvoiceLinkStatus,
  } = useSelector(shopSelector)
  const { userFarmedCoins, userTotalCoins, prizePopUpText, tonPopUp } =
    useSelector(userSelector)

  const [loading, setLoading] = useState(true)
  const [openLotteryModal, setOpenLotteryModal] = useState(false)
  const [remainingTime, setRemainingTime] = useState(timeLeft)
  const [coins, setCoins] = useState(farmedCoins)
  const [openCongratsModal, setOpenCongratsModal] = useState(false)
  const [lotteriesCount, setLotteriesCount] = useState(1)
  const [openDailyModal, setOpenDailyModal] = useState(false)
  const [openPrizeModal, setOpenPrizeModal] = useState(true)
  const [openTonModal, setOpenTonModal] = useState(true)
  const [value, setValue] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const increment = useCallback(() => {
    if (lotteriesCount < 100) {
      setLotteriesCount((prevCount) => prevCount + 1)
    }
  }, [lotteriesCount])

  const decrement = useCallback(() => {
    if (lotteriesCount > 1) {
      setLotteriesCount((prevCount) => prevCount - 1)
    }
  }, [lotteriesCount])

  const getLottery = useCallback(() => {
    setOpenLotteryModal(true)
  }, [])

  const buyLotteryTicket = useCallback(() => {
    dispatch(
      shopActions.getInvoiceLink({ id: 'lottery', count: lotteriesCount })
    )
  }, [dispatch, lotteriesCount])

  const startFarming = useCallback(() => {
    dispatch(farmActions.startFarming({ timeLeft }))
  }, [dispatch, timeLeft])

  const openBuyLotteryModalFromDailyModal = () => {
    setOpenDailyModal(false)
    setOpenLotteryModal(true)
  }

  const handleOnStoreClick = () => {
    navigate(ROUTES.profile_shop)
  }

  const navigateToSpecialCards = () => {
    navigate(ROUTES.special_cards)
  }

  const submitWalletAddress = () => {
    dispatch(
      userActions.updateDetails({ tonCoinToken: value, tonPopUp: false })
    )
    setOpenTonModal(false)
  }

  const closePrizePopUp = () => {
    dispatch(userActions.updateDetails({ prizePopUp: '' }))
    setOpenPrizeModal(false)
  }

  useEffect(() => {
    setLoading(true)

    // if (shouldOpenDailyModal()) {
    //   setOpenDailyModal(true)
    // }

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    if (timeLeft) {
      dispatch(farmActions.checkFarmingStatus())
    }

    return () => {
      clearTimeout(timeoutId)
      dispatch(shopActions.resetBoughtLotteries())
    }
  }, [])

  useEffect(() => {
    if (getFarmingStatus === LOADING_STATUSES.succeeded) {
      setCoins(farmedCoins)
      setLoading(false)
    }
  }, [getFarmingStatus])

  useEffect(() => {
    if (
      getInvoiceLinkStatus === LOADING_STATUSES.succeeded &&
      latestInvoiceLink
    ) {
      try {
        window.Telegram.WebApp.openInvoice(latestInvoiceLink.link)
      } catch (e) {
        console.warn(e.message)
      }
      const invoiceClosedHandler = (event) => {
        if (event && event.status === 'paid') {
          setTimeout(() => {
            dispatch(shopActions.getLatestLotteryTickets(lotteriesCount))
            setLotteriesCount(1)
          }, 500)
        }
        window.Telegram.WebApp.offEvent('invoiceClosed', invoiceClosedHandler)
      }
      window.Telegram.WebApp.onEvent('invoiceClosed', invoiceClosedHandler)
      setOpenLotteryModal(false)
    }
  }, [getInvoiceLinkStatus])

  useEffect(() => {
    setRemainingTime(timeLeft)

    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(timer)
            setLoading(true)
            setTimeout(() => {
              dispatch(farmActions.checkFarmingStatus())
              if (autoFarming) {
                dispatch(userActions.addFarmedCoins(coinsPerSession))
              }
            }, 4000)
            return 0
          }
          setCoins((prev) => prev + coinsPerSecond)
          return prevTime - 1000
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [timeLeft, coinsPerSecond, dispatch])

  useEffect(() => {
    if (getLatestLotteryTicketsStatus === LOADING_STATUSES.succeeded) {
      setOpenCongratsModal(true)
    }
  }, [getLatestLotteryTicketsStatus])

  return (
    <S.FarmView>
      <S.GiftWrapper onClick={navigateToSpecialCards}>
        <SpecialCard />
      </S.GiftWrapper>
      <S.StoreIconWrapper onClick={handleOnStoreClick}>
        <S.StyledImage src={Image.shop} width={80} height={80} alt='Store' />
      </S.StoreIconWrapper>
      <S.TicketWrapper onClick={getLottery}>
        <S.StyledImage
          src={Image.profileTicket}
          width={60}
          height={60}
          alt='Ticket'
        />
      </S.TicketWrapper>
      <S.TotalCoinsWrapper>
        <TotalERVCoin totalCoin={formatCoin(userTotalCoins)} />
      </S.TotalCoinsWrapper>
      <BubbleAnimation isStartAnimation={!!timeLeft} />
      <S.TotalCoinInfo>
        <S.CoinLabel>Totally farmed:</S.CoinLabel>
        <S.CoinValue>
          <VoyageIcon />
          ERV: {formatCoin(userFarmedCoins)}
        </S.CoinValue>
      </S.TotalCoinInfo>
      {loading ? (
        <S.FormStartButton>
          <Skeleton.Line width='100%' height='36px' />
        </S.FormStartButton>
      ) : !timeLeft && !autoFarming ? (
        <S.FormStartButton>
          <Button
            text={
              timeLeft === 0
                ? TASK_BUTTONS_STATES.collect
                : TASK_BUTTONS_STATES.start
            }
            onClick={startFarming}
            maxWidth='100%'
          />
        </S.FormStartButton>
      ) : (
        <S.FormFarmingInformation>
          <S.Timer>
            {getFarmingLabel(multiplier, autoFarming)}: <VoyageIcon />
            {Number(coins).toFixed(3)}
          </S.Timer>
          <S.Timer>{formatTime(remainingTime)}</S.Timer>
        </S.FormFarmingInformation>
      )}
      <Dialog
        isOpen={openLotteryModal}
        closeDialog={() => setOpenLotteryModal(false)}
      >
        <BuyLotteryContent
          buyLotteryTicket={buyLotteryTicket}
          increment={increment}
          decrement={decrement}
          setCount={setLotteriesCount}
          count={lotteriesCount}
        />
      </Dialog>
      <LotteryCongratulationsModal
        isOpen={openCongratsModal}
        onClose={() => setOpenCongratsModal(false)}
        lotteries={boughtLotteries}
      />
      <Dialog
        isOpen={openDailyModal}
        closeDialog={() => setOpenDailyModal(false)}
      >
        <DailyRewardsContent
          openLotteriesModal={openBuyLotteryModalFromDailyModal}
        />
      </Dialog>
      {prizePopUpText ? (
        <Dialog isOpen={openPrizeModal} closeDialog={closePrizePopUp}>
          <PrizePopUpContent prizePopUpText={prizePopUpText} />
        </Dialog>
      ) : null}
      {tonPopUp ? (
        <Dialog
          top
          hideCloseIcon
          isOpen={openTonModal}
          closeDialog={() => setOpenTonModal(false)}
        >
          <TonPopUpContent
            value={value}
            setValue={setValue}
            onSubmit={submitWalletAddress}
          />
        </Dialog>
      ) : null}
    </S.FarmView>
  )
}

export default FarmForm
