import {
  DAILY_TASK_TYPES,
  TASK_BUTTONS_STATES,
  TASK_SOURCES,
  TASK_SPONSORS,
  TEMP_JOIN_TASK,
} from './constants'

export const getTaskStatus = (el) => {
  if (el.gained) return TASK_BUTTONS_STATES.done
  if (el.completed)
    return el.type === DAILY_TASK_TYPES.subscribe
      ? TASK_BUTTONS_STATES.check
      : TASK_BUTTONS_STATES.claim
  return TASK_BUTTONS_STATES.start
}

export const getTaskPopupAction = (task) => {
  switch (task.source) {
    case TASK_SOURCES.sponsor:
      return task.sponsor && task.sponsor === TASK_SPONSORS.getgems
        ? TASK_BUTTONS_STATES.visit
        : TASK_BUTTONS_STATES.register
    case TASK_SOURCES.game:
      return task.description === TEMP_JOIN_TASK
        ? TASK_BUTTONS_STATES.join
        : TASK_BUTTONS_STATES.play
    case TASK_SOURCES.twitter:
      return TASK_BUTTONS_STATES.retweet
    default:
      return task.type === DAILY_TASK_TYPES.story
        ? TASK_BUTTONS_STATES.share
        : TASK_BUTTONS_STATES.follow
  }
}

export const getTaskPopupCheck = (task) => {
  return `${TASK_BUTTONS_STATES.check} ${
    task.type === DAILY_TASK_TYPES.subscribe ? 'Subscription' : ''
  }`
}

export const getOneTimeTaskText = (task) => {
  if (task.gained) return TASK_BUTTONS_STATES.done
  switch (task.source) {
    case TASK_SOURCES.game:
      return task.description === TEMP_JOIN_TASK
        ? TASK_BUTTONS_STATES.join
        : TASK_BUTTONS_STATES.play
    case TASK_SOURCES.sponsor:
      return task.sponsor && task.sponsor === TASK_SPONSORS.getgems
        ? TASK_BUTTONS_STATES.visit
        : TASK_BUTTONS_STATES.start
    default:
      return TASK_BUTTONS_STATES.start
  }
}
